.cards-container{
    display: flex;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin:20px;
    padding: 5px;
    width: 46%;
    box-sizing: border-box;
    .card-image{
        display: flex;
        flex-direction: column;
        flex: 1;
        img{
            width: 100%;
        }
    }
    .card-cart{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        // height: 300px;
        padding: 10px;
        .cart-title{
            letter-spacing: 3px;
            font-size: 20px;
            font-weight: 600;
            color: gray;
            font-family: "Roboto";
            text-align: justify;
            @media screen and (max-width:768px) {
                font-size: 14px;
                letter-spacing: 2px;
                text-align:left;
            }
        }
        .stars{
            color: #FFD600;
            font-size: 20px;
            letter-spacing: 4px;
            @media screen and (max-width:768px) {
                font-size: 10px;
                padding: 5px 0;
            }
            span{
                color: gray;
                letter-spacing: 2px;
                font-size: 18px;
                @media screen and (max-width:768px) {
                    font-size: 12px;
                }
            }
        }
        .cart-desc{
            line-height: 30px;
        }
        .cart-btn{
            display: flex;
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            background: #013B70;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 2px;
            color: #FFF;
            cursor: pointer;
            padding: 10px;
            width: 80%;;

        }
    }

    @media screen and (max-width:768px) {
        flex-direction: column;
        margin: 5px;

        .cart-desc{
            font-size: 12px;
            line-height: 18px!important;
            padding: 5px 0 10px 0px;
        }
        span{
            font-size: 13px;
            margin: 0 0 10px 0;
            color: green;
            font-weight: 500;
        }
    }
    @media screen and (max-width:768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92vw;
        .card-cart{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}


