.training-bg{
    background: url("../../images/cocks-bg.jpg");
}
.paragraph{

    text-align: left;
    margin-bottom: 10px;
}
ul{
    // background: white;
}
.item{
    text-align: left;
   
}
.processing{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    margin: 10px 0;
    @media screen and (max-width:768px) {
        flex-direction: column;
    }
}