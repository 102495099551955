.inactive{
    display: none;
}

.prev, .next{
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 15%;
    width: auto;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 30px;
    font-weight: bold;
    border-radius: 0px 5px 5px 0px;
}

.prev:hover, 
.next:hover{
    color: white;
    background: rgb(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
}

.next{
    right: 0;
    border-radius: 5px 0 0 5px;
}

.all-dots{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 20%;
    justify-content: center;
    z-index: 200;
}

.dot{
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background: rgba(8, 8, 8, 0.3);
    border-radius: 50%;
    display: inline-block;
}

.active-dot,
.dot:hover{
    background: rgb((255), 255, 255, .5);
}


/* Read more */
.read-more{
    border: 1px solid #FFF;
    color: #FFF;
    padding: 8px 20px;
    border-radius: 50px;
    margin-top: 40px;
}

@media screen and (max-width:768px) {
    
    .slider-container{
        width: 100vw;
    }
}