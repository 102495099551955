.home{
    display: flex;
    flex-direction: column;
    background: red;
    min-height: 100vh;
}
.crops{
    padding: 20px 0;
}
.crop-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

@media screen and (max-width:768px) {
    /* .home{
        background: red;
    } */
    .crops{
        width: 100vw;
    }
}