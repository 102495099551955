
.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding-top: 10px 0;
    background-color: hsl(220deg, 100%, 80%);
    // min-height: 130vh;
    padding: 30px 0;    

.center-content{
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        min-height: 400px;
        width: 500px;
        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: hsl(220deg, 100%, 55%);
        position: relative;
        @media screen and (max-width:768px) {
            width:400px;
        }
        .admin-male{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 98%;
            box-sizing: border-box;
            position: relative;
            span{
                display: flex;
                justify-content: center;
                position: absolute;
                left: 35px;
                width: 100px;
                letter-spacing: 3px;
                font-weight: 600;
            }
            span, .forgotpassword{
                color: teal;
                border-bottom: 2px solid blueviolet;
                font-size: 16px;
                cursor: pointer;
                padding-bottom: 5px;
                align-content: center;
            }
            .forgotpassword{
                position: absolute;
                right: 20px!important;
                font-weight: 500;
            }
        }
        form{
            display: flex;
            flex-direction: column;
            width: 90%;
            padding: 10px;
            margin: 0 auto;
            box-sizing: border-box;
        }
        .form-group{
            display: flex;
            flex-direction: column;
            width: 96%;
            box-sizing: border-box;
            padding: 5px;
        }
        h3{
            margin: 5px auto;
            font-size: 15px;
            color: gray;
            font-weight: 600;
        }
        label{
            font-weight: 400;
            color: gray;
            font-size: 15px;
        }
        .form-control{
            padding:10px;
            width:100%;
            margin:2px 0;
            border: 1px solid #DDDDDD;
            background: #FFF;
            border-radius: 3px;
            outline: none;
        }
        input:focus {
            background-color: #FFF;
          }
        .button-control{
            padding:10px;
            width:100%;
            margin:8px 0;
            border: none;
            outline: none;
            background: #E633AD;
            border-radius: 5px;
            color: #FFF;
            font-size: 16px;
            font-weight: 500;
            opacity: .9;
            cursor:pointer;
        }
        .password-wrapper{
            display: flex;
            text-align: center;
            justify-content: center;
            border: 1px solid #DDDDDD;
            border-radius: 3px;
            width: 100%;
            input{
                border: none;
                padding: 5px;
                font-size: 18px;
            }
            span{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        

    }
}
.login-form-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

// .check-order-container{
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     padding: 30px;
//     min-width: 90vw;
//     background-color: hsl(220deg, 100%, 80%);
// }