.crop-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.crop-container p{
    padding: 5px 20px;
}
.crop-background{
   display: flex;
   flex-direction: column;
   width: 100%;
   box-sizing: border-box;
   position: relative;
   min-height: 130vh;
}
.crop-background img{
    width: 100%;
}
.content-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 400px;
    left: 0px;
    width: 100%;
    margin: auto;
    background: #FFF;
    margin-bottom: 300px;
}
.content-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.content-column{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}
.content-column img{
    width: 98%;
}
.crop-image{
    width: 60%;
    border-radius: 50%;
}
.crop-image img{
    width: 100%;
    border-radius: 50%;
}
.crop-link{
    border: 1px solid green;
    padding: 8px 15px;
    border-radius: 50px;
    color: green;
}
.content-continue{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    padding: 20px;
}

@media screen and (max-width:768px) {
    .crop-container{
        width: 98vw;
    }
    .crop-container p{
        text-align: justify;
    }
    .crop-container ul{
        list-style: none;
    }
     .content-section{
        top: 250px;
     }
    .content-row{
        flex-direction: column;
    }
    .content-column{
        margin: 30px 0;
    }
}