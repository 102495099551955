.content-wrapper{
    display: flex;
    align-items: flex-start;
    min-height: 70vh;
}
.paddingTop{
    padding-top: 30px;
    opacity: .7;
}
.loader {
    width: 15px;
    height: 15px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 