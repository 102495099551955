.shop-container{
    display:flex; 
    flex-wrap:wrap; 
    align-items:flex-start;
    justify-content:center;
    width:100%;
    @media screen and (max-width:768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        margin: auto;
        margin: 0 8px;
        // padding: 0;
    }
}