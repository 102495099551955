*{
    // display: flex;
    // flex-direction: column;
    box-sizing: border-box;
}
.top{
    display: flex;
    padding: 0 30px;
    margin: 0;
    background: #078045;
    height: 35px;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: #FFF;
    position: sticky;
    z-index: 89;
    top: 0;
    left: 0;
    .top-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 96%;
        opacity: .9;
        position: relative;
        a{
            text-decoration: none;
            text-align: center;
            color: #FFF;
        }
        .become-investor{
            background: rgb(0, 68, 255);
            padding: 3px 10px;
            border-radius: 5px;
            font-size: 18px;
            font-weight: 400;
            &:hover {
                background: #0085BC;
                transition: 1s;
                transform: scaleX(1.5);
            }
        }
        .carts-items{
            display: none!important;
        }
        .mycarts{
            position: relative;
        }
        .cartcount{
            position: absolute;
            left: 4px;
            top: -5px;
            background: red;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            font-weight: 600;
            &:hover{
                transform: scale(1.5);
            }
        }
 
    }
}
.mobile{
    display: none;
}
a{
    text-decoration: none;
    text-align: center;
    // color: gray;
    // opacity: .9;
    font-size: 15px;
    font-weight: 400;
}
.navbar{
    margin: 0;
    padding: 0;
    display: flex;
    // height: 120px;
    height: 80px;
    box-sizing: border-box;
    background: #FFF;
    // border-bottom: 0.5px solid rgb(245, 242, 242);
    // box-shadow: 0 1px 1px rgba(0,0,0,.1);
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    position: sticky;
    z-index: 99;
    top: 35px;
    box-shadow: 1px 1px 1px #0085BC;
  

    // @media screen and (max-width: 768px) {
    //     width: 100%;
    //     padding: 30px;
    //   }
 
    .signup{
        background: #0085BC;
        text-decoration: none;
        color: #FFF;
        text-align: center;
        margin-top: 0;
        justify-content: center;
        font-size: 18px;
        // font-weight: bold;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 85%;
        height: 80px;
        .mobile{
            display: none;
        }
        li{
            list-style: none;
            margin: 5px;
            .item{
                text-decoration: none;
            }
            .main-items{
                font-size: 18px;
                font-weight: 500;
                opacity: .9;
                color: gray;
            }
        }
    }
    .logo{
        width: 100px;
    }
    .hambuger{
        display: none;
    }
}

.add-shadow{
    box-shadow: 0px 1px 10px #999;
}

.admin-user{
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.profile-menu{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    padding: 12px;
    z-index: 1;
    border-top: 1px solid green;
    li{
        padding: 8px 20px;
        margin: 5px 0;
        font-weight: 500;
        color: gray;
        border-radius: 5px;
        .item{
            font-size: 18px;
        }
    }
}

.admin-user:hover>.profile-menu{
    display: block;
    position: absolute;
    right: 2px;
    background: #FFF;
    top: 40;
    padding: 10px;
    transition: 2s linear;
    transform: rotate(360deg);
}

// Carts items 
.carts-items{
    display: none;
}
