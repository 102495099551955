.partner-container{
    background-color: #E9F8F5;
    width: 100%;
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // padding: 20px;
    box-sizing: border-box;
    @media screen and (max-width:768px) {
        flex-direction: column;
    }
    .wrapper{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .card{
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            padding: 20px;
            img{
                height: 200px;
                width: 200px;
                border-radius: 50%;
                margin: 20px;
            }
         h3{
            font-weight: bold;
            font-size: 26px;
         }
            p{
                text-align: center;
                padding: 20px 0;
                line-height: 25px;
                font-size: 18px;
            }
        }
        .register-here{
            background: #00846B;
            color: #FFF;
            border-radius: 20px;
            padding: 10px 20px;
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
            font-size: 18px;
            font-weight: bold;
            opacity: .9;
            margin-top: 5px;
        }
    }
}
@media screen and (max-width:768px) {
    .partner-container{
        padding: 0;
        margin: 0;
        width: 99vw;
    }
}