/* .hide{
    display: none;
} */
.carts-items{
    display: block;
    /* position: absolute; */
    top: 110px;
    right: 5px;
    background: #FFF;
    color: black;
    min-width: 100%;
    min-height: 100vh;
    padding: 20px;
    background: #F1F1F1;
}

.cartItems{
    display: flex;
    position: relative;
}
.close{
    position: absolute;
    top: -20px;
    right: 10px;
    cursor: pointer;
}
.fa-shopping-cart{
    font-size: 22px;
}
.cart-card{
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    flex:1;
    margin: 5px;
    background: #FFF;
    max-width: 250px; 
    border-radius: 5px;
    /* box-shadow: 1px 1px 1px #0085BC; */
    position: relative;
}
.cart-card img{
    width: 100%;
}
.close{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: red;
}
.cartitem-header{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid gray;
    border-radius: 5px;
   
}
.minus, .plus, .count{
    padding: 5px;
    font-size: 20px;
    background: #FFF;
    outline: none;
    border: none;
    flex: 1;
    background: #0085BC;
    color: white;
    cursor: pointer;
}
.count{
    width: 20px;
}

.pay-now{
    padding: 5px 20px;
    background: #EB001B;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    border-radius: 5px;
}

@media screen and (max-width:768px){
    .cartItems{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .cart-card{
        min-width: 50%;
    }
}