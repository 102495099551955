.about-container{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 20px;
   box-sizing: border-box;
    width: 100%;
    // position: relative;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    min-height: 100vh;
    .header-bg{
        width: 100%;
        background: url("../../images/chicks_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        text-align: center;
        color: blue;
        font-weight: bold;
        letter-spacing: 4px;
    }
}

.about-wrapper{
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    width: 99%;
    // align-items: center;
    box-sizing: border-box;
    min-height: 500px;
    p{
        padding: 6px 0;
        text-align: justify;
    }
}
.about-container h3{
    padding: 20px;
    margin: 10px 0;
    width: 100%;
    // text-align: center;
}

/* About me section */
.about-me{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto;
}
.headers, .titles{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.titles{
    padding: 10px;
}
.title-text{
    font-size: 25px;
    font-weight: 900;
    /* padding: 5px; */
}
.sub-title{
    font-size: 12px;
    font-weight: 600;
    letter-spacing:6px;
}
.row-lines{
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}
.first-line, .middle-line, .last-line{
    width: 50px;
    height: 2px;
    background: #4B4B4B;
    flex: 1;
}
.middle-line{
    background: #FF4C1F;
    height: 10px;
    border-radius: 10px;
}

.ceo-container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    img{
        width: 600px;
    }
    .right{
        padding-left: 30px;
    }
}

@media screen and (max-width:768px) {
    .about-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 0;
        p{
            padding: 0 10px;
        }
            }
            .ceo-container{
            width: 100%;
            flex-direction: column;
            .right{
                margin: 0;
                padding: 0;
                width: 100%;
                // background-color: red;
                // padding-right: 20px;
                p{}
            }
            img{
                width: 100%;
            }
        }
        .story{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 96vw;
            padding: 0 5px;
        }

}