.top{
    @media screen and (max-width:768px) {
        display: none;
    }
}
.navbar{
    @media screen and (max-width:768px) {
        position: sticky;
        z-index: 99;
        top: 0;
        padding: 0 10px;
    .logo{
        width: 80px;
    }
    .menu{
        background: #078045;
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        padding: 20px 0;
        opacity: .9;
        display: none;
        min-height: 75vh;
        padding-bottom: 20px;
        .mobile{
            display: block;
        }
        .main-items{
            color: #FFF!important;
            font-weight: 300!important;
        }
        
    }
    
    }
    .hambuger{
        @media screen and (max-width:768px) {
            display: block!important;
            cursor: pointer;
        }
    }
    .active{
        @media screen and (max-width:768px) {
            display: block!important;
            flex-direction: column;
            li{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
                &:hover{
                    color: red;
                }
            }
        }
    }
    .my-fa-bars{
        @media screen and (max-width:768px) {
            font-size: 30px;
            color: #078045;
        }
    }
}
.mobile{
    @media screen and (max-width:768px) {
        display: block;
    }
   
}