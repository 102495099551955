.investment-container{
    display: flex;
    flex-direction: column;
    padding: 0 0px;
    margin: 0 auto;
    width: 100%;
    .teams{
        background: url("../../images/team_1.png");
        background-repeat: no-repeat;
        background-size:cover;
        height: 500px;
        position: relative;
        p{
            position: absolute;
            top: 40%;
            left: 20%;
            width: 60%;
            background: rgba(255, 255, 255, .8);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // padding: 20px 0 10px 0;
            animation: shake 5s infinite;
            padding: 30px;
            a{
                background: #013B70;
                padding: 8px 20px;
                color: #FFF;
                opacity: 1;
                margin-top: 20px;
                letter-spacing: 3px;
                word-spacing: 10px;
                text-decoration: none;
            }
        }
    }
    .investors-section{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;
        .card-row{
            display: flex;
            align-items: flex-start;
            align-items: center;

        }
        .card{
            display: flex;
            flex-direction: column;
            border-radius: 1px 1px 1px #f6f6f6;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            margin: 10px;
            padding: 10px 0;
            .card-content p{
                text-align: center;
                padding: 2px 20px;
            }
        }
        .card-footer{
            margin-top: 20px;
        }
        .card-btn{
            width: 250px;
            padding: 8px 20px;
            background: green;
            color: white;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            text-decoration: none;
        }
    }
    @media screen and (max-width:768px) {
        .investment-container{
            margin: 0;
            padding: 0;
        }
       
        .teams{
            background: url("../../images/team_1.png");
            background-repeat: no-repeat;
            background-size:contain;
            p{
                top: 30%;
                left: 10%;
                width: 80%;
            }
        }
    .card-row{
        flex-direction: column;
        .card{
            padding-bottom: 30px;
            .card-image{
                width: 85vw;
                img{
                    width: 85vw;
                }
            }
        }
       
       
    }
    }
}