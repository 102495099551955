
.hide{
    display: none;
}
.chickens{
    display: block;
    position: absolute;
    padding: 20px;
    top: 110px;
    left: 10%;
    background: #FFF;
    min-width: 80vw;
    min-height: 200px;
    z-index: 6;
    transition: 5s ease;
    img{
        height: 300px;
        &:hover{
            transition: 2s ease-in-out;
            transform: scale(2.5);
        }
    }
    
}
