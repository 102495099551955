.major{
    background: url("../../images//multiple_chick.jpg");
    width: 100%;
    min-height: 450px;
    display:flex; 
    flex-direction:column;
}

.contact-container{
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .contact-us-container{
        background: rgba(255, 255, 255, .9);
        width: 80%;
        margin: 2% auto;
        padding: 50px;
        // background: #FFF;
        .columns{
            display: flex;
                flex-direction: column;
        .row{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            }
        }
        
    }
   
}
.contact{
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #FFF;
    width: 80%;
    border-radius: 3px;
    padding: 50px;
    margin: 20px auto;
    .row-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;
    }
    .h3{
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
    .form-row{
        display: flex;
    .form-column{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 5px;
        .form-control{
            margin: 10px;
            padding: 9px!important;
        }
    }
   
        
 }
 .textarea{
    padding: 10px 0;
    width: 80%;
 }
}

.submit-btn{
    background: #003B71;
    padding: 8px 30px;
    color: #FFF;
    width: 200px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
   }
   .submit-btn:hover{
    background: green;
   }
   @media screen and (max-width:768px) {
    .major{
        width: 100vw;
        .contact-us-container{
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            // background-color: red;
            margin: 0;
            // padding: 5px ;
            justify-content: center;
            align-items: center;
            width: 100vw;
            p{
                width: 100%;
            }
           
        }
        .contact{
            width: 90%;
            margin: 20px auto;
            padding: 0;
            .row-center{
                width: 100%;
            }
            .contact-form{
                width: 100%;
               .submit-btn{
                width: 100%;
               } 
            }
        }
        .columns{
            width: 90vw;
        }
    }
   .form-row{
    flex-direction: column;
   }
   }