.slider{
    overflow: hidden;
    box-sizing: border-box;
}
/* .active{
    display: flex;
} */
.slider figure{
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    animation: 50s slider infinite;
}
.slider figure .slide{
    width: 20%;
    float: left;
    display: flex;
}
.slide-image{
    display: flex;
    flex: 5;
    position: relative;
}
.slide-image img{
    width: 100%;
    height: auto;
}
.slide-adverts{
    position: absolute;
    top: 40%;
    left: 40%;
    width: 40%;
    background: rgba(255, 255, 255, .2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 10px 0;
    animation: shake 5s infinite;
   
  }
  .slide-adverts img{
    width: 50%;
  }
  .slide-adverts img:hover{
    transform: scale(2.5);
    transition: 2s ease-in-out;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
.slide-adverts span{
    background: #013B70;
    padding: 10px 20px;
    color: #FFF;
    opacity: 1;
    margin: 10px;
    letter-spacing: 3px;
    word-spacing: 10px;
}
.slide-description{
    display:flex;
    flex-direction: column;
    flex: 3;
    background: #078045;
    align-items: center;
    padding: 50px;
    opacity: 0;
    animation: fadeinout 15s infinite;
}

.slide-description span{
    padding: 10px 20px;
    border: 1px solid #FFF;
    border-radius: 10px;
    color: #FFF;
}
@keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    14.29%,
    85.72% {
      opacity: 1;
    }
  }
  .slide-description h4{
    font-size: 30px;
    color: #FFF;
    padding-bottom: 20px;
    opacity: .8;
  }
.slide-description p{
    font-size: 20px;
    color: #FFF;
    font-weight: bold;
    opacity: .8;
    text-transform: uppercase;
    padding: 50px 0;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
    width: 80%;
    margin-bottom: 15px;
    /* animation: rotate 1s linear; */
}
@keyframes rotate{
        0%{
            transform: rotate(0deg);
        }
        50%{
            transform: rotate(180deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }
@keyframes slider {
    0%{
        left: 0%;
    }
    20%{
        left: 0%;
    }
    25%{
        left: -100%;
    }
    45%{
        left: -100%;
    }
    50%{
        left: -200%;
    }
    70%{
        left: -200%;
    }
    75%{
        left: -300%;
    }
    95%{
        left: -300%;
    }
    100%{
        left: -400%;
    }
}
@keyframes description {
    0%{
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    100%{
        opacity: 1;
    }
}




@media screen and (max-width:768px) {
    .slide{
        flex-direction: column;
    }
    .slide-adverts{
        left: 30%;
        background: rgba(255, 255, 255, .1);
    }
    .slide-adverts span{
        opacity: 1;
    }
    
}