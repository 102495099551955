.dashboard-container{
    padding: 20px;
    width: 100%;
}

.dashboard-container hr{
    width: 100%;
    background-color: gray;
    color: gray;
    padding: 1px;
}
.container-shadow{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px 0;
    border-radius: 3px;
    align-items: center;
    justify-content: space-between;
}
.container-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.form-control{
    padding: 10px;
    border: 1px solid #DDE3E9;
    /* font-size: 14px; */
}
/* Chechout section */
.checkout{
    margin: 10px;
    border-radius: 5px;
    padding: 20px;
   min-height: 80vh;
    flex: 1;
    border: 1px solid #666;
}
.checkout .title{
    text-align: center;
    color: #FFF;
    opacity: .8;
    background-color: teal;
    border-radius: 5px;
    padding: 5px 0;
}
.checkout h3{
    text-transform: uppercase;
    color: #333;
}
.checkout-row{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #E9F8F5;
    padding: 10px 0;
    cursor: pointer;
}
.row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
.image-item{
    height: 50px;
    width: 50px;
}
.image-item img{
    height: 100%;

}
.paynow{
    padding: 15px 25px;
    font-size: 20px;
    color: #FFF;
    font-weight: 500px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    background: palevioletred;
}
.paynow:hover{
    background: rgb(138, 58, 85);
    transition: 2s ease;
    transform: scale(1.5);
}

.invoicecontainer{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
}
.invoicecontainer b{
    font-weight: 500;
}
.table{
    width: 100%;
}
.table tr th{
    text-align: start;
    padding: 5px;
}
.table tr td{
    padding: 5px;
}

hr{
    width: 100%;
    
}

.total{
    display: flex;
    flex-direction: column;
   
    width: 100%;
}
.total p{
    flex: 1;
}
.principal-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    /* padding: 0 20px; */
}
.principal-container .details{
    width:100%;
     background:green;
      color:white;
      text-align: center;
      opacity: .8;
}
.table-responsive{
    /* width: 100%; */
    
}
.table-responsive tr{
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    align-items: flex-start;
}
.table-responsive tr td{
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}

.proceed-btn{
    background: #0C84EA;
    color: #FFF;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
}
.cart-checkout{
    display: flex;
    
}
@media screen and (max-width:768px) {
    .container-row, .form-group{
        flex-direction: column;
    }
    .cart-checkout{
        flex-direction: column;
        margin: 20px 0;
    }
    .interest-row{
        display: flex;
        flex-direction: column;
        margin: 10px 0;
    }
    .checkout-btn{
        margin: 20px 0;
       
    }
    .proceed-btn, .interest-control{
        width: 100%;
    }
    .pad{
        margin: 10px 0;
    }
    .checkout-row{
        flex-direction: column;
        max-width: 100%;
    }
    .checkout-row img{
        width: 100%;
    }
}