.footer-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    padding: 30px 50px;
    background: #078045;;
    color: #FFF;
    box-sizing: border-box;
    overflow: hidden;
    border-top: 1px solid salmon;
    // position: absolute;
    width: 100%;
    // min-height: 2.5rem;        
    .footer-row{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 96%;
        margin: auto;
        .footer-column{
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: flex-start;
            justify-content: flex-start;
            h3{
                color: white;
            }
            a{
                color: #FFF;
                text-decoration: none;
            }
        input{
            padding: 8px;
            border-radius: 4px;
            margin: 8px 0;
            border: none;
            outline: none;
            width: 100%;
        }
        button{
            background: #0C84EA;
            border-radius: 5px;
            padding: 8px;
            outline: none;
            border: none;
            width: 100%;
            color: white;
            font-size: 16px;
            &:hover{
                background: skyblue;
            }

        }
        }
    }  
    @media screen and (max-width: 768px) {
        width: 98%;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        padding: 10px;
        margin: 0 auto;
        .footer-row{
            display: flex;
            flex-direction: column;
            margin: 10px 0;
            justify-content: center;
            width: 100%;
        }
      } 
}