.main{
    display: flex;
    flex-direction: column;
    // padding: 0 5px;
    
    .main-top{
        display: flex;
        justify-content: space-between;
        background-color: red;
    }
    .container{
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
}

.center-content{
    display: flex;
    justify-content: center;
    padding: 30px 0;
}
